/* eslint-disable */
import * as React from 'react';
/**
 * Feel free to play around with React.
 * See your changes over at: [http://localhost:8000/playground](http://localhost:8000/playground)
 */
const Playground = () => {
  const [color, setColor] = React.useState('red');
  return <div>Welcome to the playground!</div>;
};

export default Playground;
